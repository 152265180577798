import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/PlanEnums';
import { PlanModuleStore, StoreError, SystemError } from '@/models/StoreModel';

@Module
export default class PlanModule extends VuexModule implements PlanModuleStore {
  actionError: StoreError | null = null;

  listPlans = [];
  plan = null;
  /**
   * Get users
   * @returns array
   */
  get [Getters.GET_PLANS](): any {
    return this.listPlans;
  }

  get [Getters.GET_PLAN](): any {
    return this.plan
  }

  @Mutation
  [Mutations.SET_PLANS](plans) {
    this.listPlans = plans;
  }

  @Mutation
  [Mutations.SET_PLAN](plan) {
    this.plan = plan;
  }

  /**
   * Get authentication errors
   * @returns array
   */
  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_PLANS](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.parameters(params);
      ApiService.get('admin/plans')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLANS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_PLAN](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('admin/plans', payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLANS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PLAN](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/plans/${payload.id}`, payload.data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLANS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PLAN](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/plans/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLANS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }
}
