import { App } from 'vue';
import DataTable from 'datatables.net-vue3';

/**
 * Initialize Inline-Svg component
 * @param app vue instance
 */
export function initDataTable(app: App<Element>) {
  app.component('DataTable', DataTable);
  app.use(DataTable);
}
