import ApiService from '@/core/services/ApiService';
import { Action, Mutation, Module, VuexModule } from 'vuex-module-decorators';

import { TenantReview } from '@/models/TenantReviewModel';
import { TenantReviewModuleStore, StoreError } from '@/models/StoreModel';

import { Actions, Getters, Mutations } from '../enums/TenantReviewEnums';
import { AxiosRequestConfig } from 'axios';

@Module
export default class TenantReviewModule
  extends VuexModule
  implements TenantReviewModuleStore
{
  actionError: StoreError | null = null;

  allTenantReview = [];
  tenantReview = {} as unknown as TenantReview;
  tenantReviews = [];

  tenantReviewListError: StoreError | null = null;
  tenantReviewLoading = false;
  tenantReviewListLoading = false;
  tenantLedger = '';
  tenantLedgerFile = '';
  uploadFileError: StoreError | null = null;
  agentAvatar = '';
  /**
   * Get all tenant reviews
   * @returns TenantReview
   */
  get [Getters.GET_ALL_TENANT_REVIEWS](): TenantReview[] {
    return this.allTenantReview;
  }

  /**
   * Get all tenant review
   * @returns TenantReview
   */
  get [Getters.GET_TENANT_REVIEWS](): TenantReview[] {
    return this.tenantReviews;
  }

  /**
   * Get all tenant review
   * @returns TenantReview
   */
  get [Getters.GET_TENANT_REVIEW](): TenantReview {
    return this.tenantReview as unknown as TenantReview;
  }

  /**
   * Get current error for the resource actions
   * @returns resource action error
   */
  get [Getters.GET_TENANT_REVIEW_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource list error
   */
  get [Getters.GET_TENANT_REVIEW_LIST_ERROR](): StoreError {
    return this.tenantReviewListError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_TENANT_REVIEW_LIST_LOADING](): StoreError {
    return this.tenantReviewListLoading as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_TENANT_REVIEW_LOADING](): StoreError {
    return this.tenantReviewLoading as unknown as StoreError;
  }

  get [Getters.GET_TENANT_REVIEW_LEDGER](): string {
    return this.tenantLedger;
  }

  get [Getters.GET_TENANT_REVIEW_LEDGER_FILE](): any {
    return this.tenantLedgerFile;
  }

  get [Getters.GET_UPLOAD_FILE_ERROR](): StoreError {
    return this.uploadFileError as unknown as StoreError;
  }

  get [Getters.GET_AGENT_AVATAR](): string {
    return this.agentAvatar;
  }

  @Mutation
  [Mutations.SET_UPLOAD_FILE_ERROR](errors) {
    this.uploadFileError = errors as StoreError;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_LEDGER_FILE](tenantLedgerFile) {
    this.tenantLedgerFile = tenantLedgerFile;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_LEDGER](tenantLedger) {
    this.tenantLedger = tenantLedger;
  }

  @Mutation
  [Mutations.SET_ALL_TENANT_REVIEWS](tenantReviews) {
    this.allTenantReview = tenantReviews;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW](tenantReview) {
    this.tenantReview = tenantReview;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEWS](tenantReviews) {
    this.tenantReviews = tenantReviews;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_ACTION_ERROR](errors) {
    this.actionError = errors as StoreError;
  }

  @Mutation
  [Mutations.SET_TENANT_REVIEW_LIST_ERROR](errors) {
    this.tenantReviewListError = errors as StoreError;
  }

  @Mutation
  [Mutations.SET_AGENT_AVATAR](avatar) {
    this.agentAvatar = avatar;
  }

  @Action
  [Actions.FETCH_ALL_TENANT_REVIEWS](tenantId) {
    console.log('FETCH_ALL_TENANT_REVIEWS', tenantId);
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/tenants/${tenantId}/reviews`, '', {
        params: { returnAll: true },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_TENANT_REVIEWS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_LIST_ERROR,
            response.data.errors || response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TANANT_REVIEW_BY_REFERENCE_NO](refNo) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/references/${refNo}`)
        .then((response) => {
          this.context.commit(Mutations.SET_TENANT_REVIEW, response.data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_LIST_ERROR,
            response.data.errors || response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_TENANT_REVIEW](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('admin/tenants/reviews', payload)
        .then((response) => {
          this.context.commit(Mutations.SET_TENANT_REVIEW, response.data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.SEND_TENANT_REVIEW_TO_EMAILS](params) {
    console.log('SEND_TENANT_REVIEW_TO_EMAILS', params);
    return new Promise<void>((resolve, reject) => {
      const formData = new FormData();
      formData.append('emails', JSON.stringify(params.emails));
      formData.append('refNo', params.refNo);
      formData.append('report', params.report);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      ApiService.post(
        `admin/tenants/reviews/${params.refNo}/send`,
        formData,
        config
      )
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_TENANT_REVIEW_LEDGER](payload) {
    const form = new FormData();
    form.append('ledger_file', payload);

    return new Promise<void>((resolve, reject) => {
      ApiService.post('admin/tenants/reviews/uploads', form)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TENANT_REVIEW_LEDGER, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_UPLOAD_FILE_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TENANT_REVIEW_LEDGER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        `admin/tenants/${payload.tenantId}/reviews/${payload.refId}/ledger`,
        '',
        {
          responseType: 'arraybuffer',
        }
      )
        .then((response) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_LEDGER_FILE,
            response.data
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_TENANT_REVIEW_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }
}
