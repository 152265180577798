enum Actions {
  PLAN = 'accountPlant',
  FETCH_PLANS = 'fetchPlans',
  STORE_PLAN = 'storePlan',
  FETCH_PLAN = 'fetchPlan',
  UPDATE_PLAN = 'updatePlan',
  DELETE_PLAN = 'deletePlan'
}

enum Mutations {
  SET_PLAN = 'setPlan',
  SET_PLANS = 'setPlans',
  SET_ACTION_ERROR = 'setActionError'
}

enum Getters {
  GET_PLAN = 'getPlan',
  GET_PLANS = 'getPlans',
  GET_ACTION_ERROR = 'getActionError'
}

export { Actions, Mutations, Getters };
