import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/DashboardEnums';
import { DashboardModuleStore, StoreError } from '@/models/StoreModel';

@Module
export default class AgencyModule
  extends VuexModule
  implements DashboardModuleStore
{
  actionError: StoreError | null = null;
  statistics = null;

  get [Getters.GET_STATISTICS](): any {
    return this.statistics;
  }

  get [Getters.GET_ERRORS](): any {
    return this.actionError;
  }

  @Mutation
  [Mutations.SET_STATISTICS](stats) {
    this.statistics = stats;
  }

  @Mutation
  [Mutations.SET_ERRORS](errors) {
    this.actionError = errors;
  }

  @Action
  [Actions.FETCH_STATISTICS](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(
        `admin/statistics/dashboard?from=${params.from}&to=${params.to}`
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATISTICS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }
}
