enum Actions {
  FETCH_STARTUP_CARDS = 'fetchStartupCards',
  FETCH_STARTUP_CARD = 'fetchStartupCard',
  STORE_STARTUP_CARD = 'storeStartupCard',
  UPDATE_STARTUP_CARD = 'updateStartupCard',
  DELETE_STARTUP_CARD = 'deleteStartupCard',
}

enum Mutations {
  SET_STARTUP_CARDS = 'setStartupCards',
  SET_STARTUP_CARD = 'setStartupCard',
  SET_ACTION_ERROR = 'setStartupActionError',
}

enum Getters {
  GET_STARTUP_GUIDES = 'getStartupCards',
  GET_STARTUP_GUIDE = 'getStartupCard',
  GET_ACTION_ERROR = 'getStartupActionError',
}

export { Actions, Mutations, Getters };
