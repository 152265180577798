import store from '@/store';
import {
  Mutations as StoreMutations,
  Actions as StoreActions,
} from '@/store/enums/StoreEnums';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import {
  ForgotPasswordRouteGuard,
  LoginRouteGuard,
  ProtectedRouteGuard,
  SubmitReviewRouteGuard,
  AccountVerificationRouteGuard,
  SubscriptionRouteGuard,
  RequestTenantReferenceGuard,
  ProfileRouteGuard,
} from './guards';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'protected-pages',
    redirect: '/sign-in',
    component: () => import('@/views/new-design/Layout.vue'),
    beforeEnter: [
      RequestTenantReferenceGuard.main(),
      ProtectedRouteGuard.isLoggedIn(),
    ],
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/new-design/Dashboard.vue'),
        beforeEnter: [
          ProtectedRouteGuard.main(),
          // RequestTenantReferenceGuard.hasRequestRef(),
        ],
      },
      {
        path: '/agencies',
        name: 'agencies',
        component: () => import('@/views/new-design/Agencies.vue'),
        children: [
          {
            path: '',
            name: 'list-agency',
            component: () =>
              import('@/views/new-design/pages/Agencies/List.vue'),
          },
          {
            path: ':agent_id',
            name: 'view-agency',
            component: () =>
              import('@/views/new-design/pages/Agencies/View.vue'),
          },
        ],
      },
      {
        path: '/tenants',
        name: 'tenants',
        component: () => import('@/views/new-design/Tenants.vue'),
        children: [
          {
            path: '',
            name: 'list-tenant',
            component: () =>
              import('@/views/new-design/pages/Tenants/List.vue'),
          },
          {
            path: ':tenant_id',
            component: () =>
              import('@/views/new-design/pages/Tenants/View.vue'),
            children: [
              {
                path: '',
                name: 'view-tenant-details',
                component: () =>
                  import('@/views/new-design/pages/Tenants/ViewTenant.vue'),
              },
              {
                path: ':tenant_ref_id',
                name: 'view-tenant-reference',
                component: () =>
                  import('@/views/new-design/pages/Tenants/ViewReference.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/references',
        name: 'references',
        component: () => import('@/views/new-design/TenantReferences.vue'),
        children: [
          {
            path: '',
            name: 'list-references',
            component: () =>
              import('@/views/new-design/pages/TenantReferences/List.vue'),
          },
          {
            path: ':reference_id',
            name: 'view-reference-report',
            component: () =>
              import('@/views/new-design/pages/TenantReferences/View.vue'),
          },
        ],
      },
      {
        path: '/requested-references',
        name: 'requested-references',
        component: () => import('@/views/new-design/RequestedReference.vue'),
        children: [
          {
            path: '',
            name: 'list-requested-references',
            component: () =>
              import('@/views/new-design/pages/RequestedReference/List.vue'),
          },
        ],
      },
      {
        path: '/plan',
        name: 'plan',
        component: () => import('@/views/pages/plan/Plan.vue'),
        beforeEnter: [ProtectedRouteGuard.main()],
      },
      {
        path: '/tenant-report',
        name: 'tenant-report',
        component: () => import('@/views/new-design/TenantReport.vue'),
        beforeEnter: [
          ProtectedRouteGuard.main(),
          ProtectedRouteGuard.verified(),
        ],
        children: [
          {
            path: ':refNo',
            name: 'view-tenant-review',
            component: () => import('@/views/new-design/ViewTenantReview.vue'),
          },
          {
            path: 'tenant',
            name: 'view-tenant',
            // component: () => import('@/views/pages/tenant/ViewTenant.vue'),

            component: () => import('@/views/new-design/ViewTenantReview.vue'),
            children: [
              {
                path: '',
                redirect: '/tenant-report/search',
              },
              {
                path: ':tenantId',
                name: 'view-tenant-reports',
                // component: () => import('@/views/pages/tenant/ViewTenant.vue'),
                component: () =>
                  import('@/views/new-design/ViewTenantReview.vue'),
                beforeEnter: SubscriptionRouteGuard.hasNoSubscription(),
              },
            ],
          },
          {
            path: 'search',
            name: 'search-tenant-report',
            component: () => import('@/views/new-design/SearchTenant.vue'),
          },
          {
            path: 'submit',
            name: 'submit-tenant-review',
            component: () =>
              import('@/views/new-design/SubmitTenantReview.vue'),
            beforeEnter: SubmitReviewRouteGuard.main(),
          },
          {
            path: 'request',
            name: 'request-tenant-report',
            component: () =>
              import('@/views/new-design/RequestTenantReference.vue'),
          },
        ],
      },
      {
        path: '/profile',
        redirect: '/profile/overview',
        component: () => import('@/views/new-design/Profile.vue'),
        name: 'my-profile-over-view',
        children: [
          {
            path: '/profile/overview',
            component: () =>
              import('@/views/new-design/pages/Profile/Overview.vue'),
          },
          {
            path: '/profile/business-details',
            component: () =>
              import('@/views/new-design/pages/Profile/BusinessDetails.vue'),
            beforeEnter: [ProfileRouteGuard.isPrincipal()],
          },
          {
            path: '/profile/password-reset',
            component: () =>
              import('@/views/new-design/pages/Profile/PasswordReset.vue'),
          },
        ],
        beforeEnter: [ProtectedRouteGuard.main()],
      },
      {
        path: '/verify-account',
        name: 'request-account-verification-mail',
        component: () => import('@/views/VerifyAccountNotice.vue'),
        beforeEnter: [
          ProtectedRouteGuard.isNotVerified(),
          AccountVerificationRouteGuard.request(),
        ],
      },
      {
        path: '/account/profile/update',
        name: 'complete-account-profile',
        component: () => import('@/views/new-design/CompleteProfile.vue'),
        beforeEnter: [ProtectedRouteGuard.isVerified()],
      },
      {
        path: '/settings',
        component: () => import('@/views/new-design/Settings.vue'),
        name: 'settings-page',
        beforeEnter: [ProtectedRouteGuard.main()],
        children: [
          {
            path: '',
            name: 'settings-main',
            component: () =>
              import('@/views/new-design/pages/Settings/Index.vue'),
            beforeEnter: [ProtectedRouteGuard.main()],
          },
          {
            path: 'users',
            name: 'user-namangement',
            component: () =>
              import('@/views/new-design/pages/Settings/Users.vue'),
            beforeEnter: [
              ProtectedRouteGuard.main(),
              ProtectedRouteGuard.isAdminLevel(),
            ],
          },

          {
            path: 'change-password',
            name: 'password-namangement',
            component: () =>
              import('@/views/new-design/pages/Settings/ChangePassword.vue'),
            beforeEnter: [ProtectedRouteGuard.main()],
          },
          {
            path: 'templates',
            name: 'template-editor-page',
            redirect: { name: 'email-template-editor-page' },
            component: () =>
              import('@/views/new-design/pages/Settings/Templates.vue'),
            children: [
              {
                path: 'email',
                name: 'email-template-editor-page',
                meta: {
                  template: 'email',
                },
                component: () =>
                  import(
                    '@/views/new-design/pages/Settings/Templates/EmailEditor.vue'
                  ),
              },
              {
                path: 'sms',
                name: 'sms-template-editor-page',
                meta: {
                  template: 'sms',
                },
                component: () =>
                  import(
                    '@/views/new-design/pages/Settings/Templates/SMSEditor.vue'
                  ),
              },
            ],
            beforeEnter: [
              ProtectedRouteGuard.main(),
              ProtectedRouteGuard.isAdminLevel(),
            ],
          },
          {
            path: 'reminders',
            name: 'reminder-editor-page',
            redirect: { name: 'reminder-email-template-editor-page' },
            component: () =>
              import('@/views/new-design/pages/Settings/Reminders.vue'),
            children: [
              {
                path: 'email',
                name: 'reminder-email-template-editor-page',
                meta: {
                  template: 'email',
                },
                component: () =>
                  import(
                    '@/views/new-design/pages/Settings/Reminders/EmailEditor.vue'
                  ),
              },
              {
                path: 'sms',
                name: 'reminder-sms-template-editor-page',
                meta: {
                  template: 'sms',
                },
                component: () =>
                  import(
                    '@/views/new-design/pages/Settings/Reminders/SMSEditor.vue'
                  ),
              },
            ],
            beforeEnter: [
              ProtectedRouteGuard.main(),
              ProtectedRouteGuard.isAdminLevel(),
            ],
          },
          {
            path: 'assets/',
            name: 'assets-manager-slash',
            component: () =>
              import('@/views/new-design/pages/Settings/Assets.vue'),
            children: [
              {
                path: '',
                name: 'assets-manager-index',
                component: () =>
                  import('@/views/new-design/pages/Settings/Assets/Index.vue'),
              },
            ],
          },
          {
            path: 'assets',
            name: 'assets-manager',
            component: () =>
              import('@/views/new-design/pages/Settings/Assets.vue'),
            children: [
              {
                path: '',
                name: 'assets-manager-index',
                component: () =>
                  import('@/views/new-design/pages/Settings/Assets/Index.vue'),
              },
              {
                path: ':id',
                name: 'assets-manager-view',
                component: () =>
                  import('@/views/new-design/pages/Settings/Assets/Index.vue'),
              },
            ],
          },
          {
            path: 'plans',
            name: 'plan-manager',
            component: () =>
              import('@/views/new-design/pages/Settings/Plans.vue'),
          },
          {
            path: 'startup',
            name: 'startup-guide-manager',
            component: () =>
              import('@/views/new-design/pages/Settings/Startup.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('@/views/new-design/SignIn.vue'),
    beforeEnter: [LoginRouteGuard.isLoggedIn()],
  },
  // {
  //   path: '/sign-up',
  //   name: 'sign-up',
  //   component: () => import('@/views/new-design/SignUp.vue'),
  // },
  {
    path: '/sign-in-options',
    name: 'sign-in-options',
    component: () => import('@/views/new-design/SignInOptions.vue'),
  },
  {
    path: '/tenant-reference-request',
    name: 'tenant-reference-request',
    component: () => import('@/views/new-design/SubmitReportSignUpOption.vue'),
    beforeEnter: [
      LoginRouteGuard.main(),
      RequestTenantReferenceGuard.refVerified(),
    ],
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/new-design/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password-page',
    component: () => import('@/views/new-design/ResetPassword.vue'),
    children: [
      {
        path: ':token',
        name: 'verify-reset-password-token',
        component: () => import('@/views/new-design/ResetPassword.vue'),
        beforeEnter: ForgotPasswordRouteGuard,
      },
    ],
  },
  {
    path: '/account',
    name: 'acccount-layout',
    component: () => import('@/layout/AccountLayout.vue'),
    children: [
      {
        path: 'password-reset',
        name: 'reset-account-password',
        component: () =>
          import('@/components/forms/forgot-password/ResetPasswordForm.vue'),
      },
      {
        path: 'verify',
        name: 'account-verification',
        component: () => import('@/views/VerifyAccount.vue'),
        children: [
          {
            path: ':token',
            name: 'validate-account-verify-token',
            component: async () => {
              return import('@/views/account/verification/VerifyToken.vue');
            },
            beforeEnter: AccountVerificationRouteGuard.token(),
          },
          {
            path: 'request',
            name: 'request-new-token',
            component: async () => {
              return import('@/views/account/RequestNewToken.vue');
            },
            beforeEnter: AccountVerificationRouteGuard.request(),
          },
          {
            path: 'renew',
            name: 'resend-verify-account-page',
            component: async () => {
              return import('@/views/account/ResendVerificationCode.vue');
            },
          },
        ],
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/views/new-design/Layout.vue'),
    children: [
      {
        path: 'verify',
        name: 'account-verification-notification-page',
        component: () => import('@/views/new-design/Verify.vue'),
        // component: () => import('@/views/RequestVerifyAccountMail.vue'),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/error/Error404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  strict: true,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(StoreMutations.RESET_LAYOUT_CONFIG);

  next();
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
