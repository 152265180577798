enum Actions {
  SEND_REQUEST_TENANT_REFERENCE = 'sendRequestTenantReference',
  CHECK_TENANT_REFERENCE = 'checkTenantReference',
  REMOVE_STORED_REFERENCE = 'removeStoredReference',
  FETCH_REFERENCES = 'fetchReferences',
}

enum Mutations {
  SET_ACTION_ERROR = 'setReferenceActionError',
  SET_TENANT_REFERENCE = 'setTenantReference',
  SET_REFERENCES = 'setReferences',
}

enum Getters {
  GET_ACTION_ERROR = 'getReferenceActionError',
  GET_TENANT_REFERENCE = 'getTenantReference',
  GET_REFERENCES_LISTS = 'getReferencesLists',
}

export { Actions, Mutations, Getters };
