import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/StartupGuideEnums';
import { StartupGuideModuleStore, StoreError } from '@/models/StoreModel';

@Module
export default class StartupGuideModule
  extends VuexModule
  implements StartupGuideModuleStore
{
  actionError: StoreError | null = null;
  startupGuides = [];

  get [Getters.GET_STARTUP_GUIDES](): any {
    return this.startupGuides;
  }

  get [Getters.GET_ACTION_ERROR](): any {
    return this.actionError;
  }

  @Mutation
  [Mutations.SET_STARTUP_CARDS](items) {
    this.startupGuides = items;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](errors) {
    this.actionError = errors;
  }

  @Action
  [Actions.FETCH_STARTUP_CARDS](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/startup/cards`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_STARTUP_CARDS, data.data);
        resolve();
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ACTION_ERROR, response);
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_STARTUP_CARD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/startup/cards/${ payload.id }`, payload)
      .then(({ data }) => {
        // this.context.commit(Mutations.SET_STARTUP_CARDS, data.data);
        resolve(data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ACTION_ERROR, response);
        reject();
      });
    });
  }

  @Action
  [Actions.DELETE_STARTUP_CARD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/startup/cards/${ payload.id }`)
      .then(({ data }) => {
        // this.context.commit(Mutations.SET_STARTUP_CARDS, data.data);
        resolve(data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ACTION_ERROR, response);
        reject();
      });
    });
  }
}
