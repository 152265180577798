import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

import { Actions, Getters, Mutations } from '@/store/enums/SubscriptionEnums';

import { StoreError, SubscriptionModuleStore } from '@/models/StoreModel';
import { Subscription } from '@/models/SubscriptionModel';

@Module
export default class SubscriptionModule
  extends VuexModule
  implements SubscriptionModuleStore
{
  actionError: StoreError | null = null;

  allSubscriptions = [];
  subscription = {} as unknown as Subscription;
  subscriptions = [];

  get [Getters.GET_SUBSCRIPTIONS](): Subscription[] {
    return this.subscriptions;
  }

  get [Getters.GET_SUBSCRIPTION](): Subscription {
    return this.subscription;
  }

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_SUBSCRIPTIONS](Subscriptions) {
    this.subscriptions = Subscriptions;
  }

  @Mutation
  [Mutations.SET_SUBSCRIPTION](Subscription) {
    this.subscription = Subscription;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.FETCH_CURRENT_SUBSCRIPTION](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('admin/account/subscription', payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SUBSCRIPTION, data.data);
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response);
          reject();
        });
    });
  }

  @Action
  [Actions.CANCEL_SUBSCRIPTION_AT_PERIOD_END](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('admin/account/subscription/cancel/at_period_end')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SUBSCRIPTION, {});
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.CANCEL_SUBSCRIPTION_IMMEDIATELY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('admin/account/subscription/cancel/immediately')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SUBSCRIPTION, {});
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_SUBSCRIPTIONS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('admin/subscriptions', payload)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SUBSCRIPTION,
            data.data.subscriptions
          );
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.MAKE_AFFILIATE_SUBSCRIPTION](payload) {
    console.log('payload', payload);
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/agencies/${payload.id}/affiliate`)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_SUBSCRIPTION,
            data.data.subscriptions
          );
          resolve();
        })
        .catch((response) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }
}
