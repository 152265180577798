import CookieService from '@/core/services/CookieService';

const RequestTenantReferenceGuard = {
  main: () => async (to, from, next) => {
    const sessionToken = CookieService.get('sessionToken');
    const sessionValid = CookieService.get('sessionValid');

    if (to.name == 'submit-tenant-review' && !sessionToken && !sessionValid) {
      return next({
        name: 'tenant-reference-request',
        query: { ref: to.query.ref },
      });
    }

    next();
  },
  refVerified: () => async (to, from, next) => {
    if (to.query.ref) {
      localStorage.setItem('tenantRequestReference', to.query.ref);
    } else {
      return next({ name: 'sign-in' });
    }

    next();
  },
  hasRequestRef: () => async (to, from, next) => {
    const ref = localStorage.getItem('tenantRequestReference');
    if (ref) {
      return next({ name: 'submit-tenant-review', query: { ref: ref } });
    }

    next();
  },
};

export default RequestTenantReferenceGuard;
