enum Actions {
  ADD_USER_LIST = 'addUserList',
  FETCH_USER_LIST = 'fetchUserList',
  DELETE_USER_LIST = 'deleteUserList',
  EDIT_USER_FROM_LIST = 'editUserList',
}

enum Mutations {
  SET_USER_LIST = 'setUserList',
  SET_USER_PAGINATION = 'setUserPagination',
  SET_USER_ADDED = 'setUserAdded',
  SET_ERROR = 'setError',
}

enum Getters {
  GET_USER_ADDED = 'getUserAdded',
  GET_USER_LIST = 'getUserList',
  GET_ERROR = 'getError',
}

export { Actions, Mutations, Getters };
