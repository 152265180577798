import ApiService from '@/core/services/ApiService';
import { Actions, Mutations, Getters } from '@/store/enums/ReminderEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { RemindersModuleStore, StoreError } from '@/models/StoreModel';
import { Template } from '@/models/TemplateModel';
import { Reminder } from '@/models/ReminderModel';
import { ReminderList } from '@/models/ReminderListModel';

@Module
export default class RemindersModule
  extends VuexModule
  implements RemindersModuleStore
{
  actionError: StoreError | null = null;

  title = '';
  reminderLists = [] as Array<ReminderList>;
  placeholders = [] as Array<string>;

  template = {} as unknown as Template;

  reminders = [] as Array<Reminder>;
  reminder = {} as unknown as Reminder;

  get [Getters.GET_TITLE](): string {
    return this.title;
  }

  get [Getters.GET_REMINDER_LIST](): ReminderList[] {
    return this.reminderLists;
  }

  get [Getters.GET_REMINDERS](): Array<Reminder> {
    return this.reminders;
  }

  get [Getters.GET_REMINDER](): Reminder {
    return this.reminder;
  }

  get [Getters.GET_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  get [Getters.GET_PLACEHOLDERS](): Array<string> {
    return this.placeholders;
  }

  @Mutation
  [Mutations.SET_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Mutation
  [Mutations.SET_TITLE](title) {
    this.title = title;
  }

  @Mutation
  [Mutations.SET_REMINDER](reminder) {
    this.reminder = reminder;
  }

  @Mutation
  [Mutations.SET_REMINDERS](reminders) {
    this.reminders = reminders;
  }

  @Mutation
  [Mutations.SET_REMINDER_LIST](reminders) {
    this.reminderLists = reminders;
  }

  @Mutation
  [Mutations.SET_PLACEHOLDERS](placeholders) {
    this.placeholders = placeholders;
  }

  @Action
  [Actions.FETCH_REMINDER_LIST]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('admin/reminders/system-names')
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_REMINDER_LIST,
            data.data.reminder_lists
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  /**
   *
   * @param type string [email | sms]
   * @param payload object
   * @returns void
   */
  @Action
  [Actions.CREATE_REMINDER]({ type, data }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/reminders/${type}`, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REMINDER, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_REMINDER]({ type, id, data }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/reminders/${type}/${id}`, data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REMINDER, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_REMINDERS_BY_NAME]({ type, systemName }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/reminders/${type}/${systemName}`)
        .then(({ data }) => {
          console.log('FETCH_REMINDERS_BY_NAME', data);
          this.context.commit(Mutations.SET_REMINDERS, data.data.reminders);

          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_REMINDER_BY_DAY]({ type, systemName, days }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/reminders/${type}/${systemName}/${days}`)
        .then(({ data }) => {
          console.log('FETCH_REMINDER_BY_DAY', data);
          this.context.commit(Mutations.SET_REMINDER, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_REMINDER_BY_ID]({ type, systemName, id }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/reminders/${type}/${systemName}/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REMINDER, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_REMINDER]({ type, id }) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/reminders/${type}/${id}`)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_PLACEHOLDERS](systemName = '') {
    if (systemName === '') return;

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/placeholders/reminders/${systemName}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PLACEHOLDERS, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ACTION_ERROR, response.data);
          reject();
        });
    });
  }
}
