enum Actions {
  FETCH_ALL_TENANT_REVIEWS = 'retrieveTenantReviews',
  FETCH_TENANT_REVIEW = 'fetchTenantReview',
  FETCH_TANANT_REVIEW_BY_REFERENCE_NO = 'fetchTenantReviewByRefNo',
  CREATE_TENANT_REVIEW = 'createTenantReview',
  UPLOAD_TENANT_REVIEW_LEDGER = 'uploadTenantReviewLedger',
  SEND_TENANT_REVIEW_TO_EMAILS = 'sendTenantReviewToEmails',
  FETCH_TENANT_REVIEW_LEDGER = 'fetchTenantReviewLedger',
  FETCH_AGENT_AVATAR = 'fetchAgentAvatar',
}

enum Mutations {
  SET_ALL_TENANT_REVIEWS = 'setAllTenantReviews',
  SET_TENANT_REVIEW = 'setTenantReview',
  SET_TENANT_REVIEWS = 'setTenantReviews',
  SET_TENANT_REVIEW_ACTION_ERROR = 'setTenantReviewActionError',
  SET_TENANT_REVIEW_LIST_ERROR = 'setTenantReviewListError',
  SET_TENANT_REVIEW_LIST_LOADING = 'setTenantReviewListLoading',
  SET_TENANT_REVIEW_LOADING = 'setTenantReviewLoading',
  SET_TENANT_REVIEW_LEDGER = 'setTenantReviewLedger',
  SET_TENANT_REVIEW_LEDGER_FILE = 'setTenantReviewLedgerFile',
  SET_UPLOAD_FILE_ERROR = 'setUploadFileError',
  SET_AGENT_AVATAR = 'setAgentAvatar',
}

enum Getters {
  GET_ALL_TENANT_REVIEWS = 'getAllTenantReviews',
  GET_TENANT_REVIEWS = 'getTenantReviews',
  GET_TENANT_REVIEW = 'getTenantReview',
  GET_TENANT_REVIEW_ACTION_ERROR = 'getTenantReviewActionError',
  GET_TENANT_REVIEW_LIST_ERROR = 'getTenantReviewListError',
  GET_TENANT_REVIEW_LIST_LOADING = 'getTenantReviewListLoading',
  GET_TENANT_REVIEW_LOADING = 'getTenantReviewLoading',
  GET_TENANT_REVIEW_LEDGER = 'getTenantReviewLedger',
  GET_TENANT_REVIEW_LEDGER_FILE = 'getTenantReviewLedgerFile',
  GET_UPLOAD_FILE_ERROR = 'getUploadFileError',
  GET_AGENT_AVATAR = 'getAgentAvatar',
}

export { Actions, Mutations, Getters };
