import ApiService from '@/core/services/ApiService';
import { Action, Mutation, Module, VuexModule } from 'vuex-module-decorators';

import { Tenant } from '@/models/TenantModel';
import { TenantModuleStore, StoreError } from '@/models/StoreModel';

import { Actions, Getters, Mutations } from '../enums/TenantEnums';
import { AxiosRequestConfig } from 'axios';

@Module
export default class TenantModule
  extends VuexModule
  implements TenantModuleStore
{
  actionError: StoreError | null = null;
  searchActionError: StoreError | null = null;

  allTenants = [];
  tenant = {} as unknown as Tenant;
  existingTenant = {} as unknown as Tenant;
  tenants = [];

  pagination = null;
  tenantListError = null;
  tenantLoading = false;
  tenantListLoading = false;
  tenantReferences = [];
  /**
   * Get all tenant
   * @returns Tenant
   */
  get [Getters.GET_ALL_TENANTS](): Tenant[] {
    return this.allTenants;
  }

  /**
   * Get all tenant
   * @returns Tenant
   */
  get [Getters.GET_SEARCH_TENANT_LIST](): Tenant[] {
    return this.tenants;
  }

  /**
   * Get all tenant
   * @returns Tenant
   */
  get [Getters.GET_TENANTS](): Tenant[] {
    return this.tenants;
  }

  /**
   * Get all tenant
   * @returns Tenant
   */
  get [Getters.GET_TENANT](): Tenant {
    return this.tenant as unknown as Tenant;
  }
  /**
   * Get existing tenant
   * @returns Tenant
   */
  get [Getters.GET_EXISTING_TENANT](): Tenant {
    return this.existingTenant as unknown as Tenant;
  }

  /**
   * Get current error for the resource actions
   * @returns resource action error
   */
  get [Getters.GET_TENANT_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource list error
   */
  get [Getters.GET_TENANT_LIST_ERROR](): StoreError {
    return this.tenantListError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_TENANT_LIST_LOADING](): StoreError {
    return this.tenantListLoading as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_TENANT_LOADING](): StoreError {
    return this.tenantLoading as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource loading
   */
  get [Getters.GET_SEARCH_TENANT_ACTION_ERROR](): StoreError {
    return this.searchActionError as unknown as StoreError;
  }

  get [Getters.GET_TENANT_REFERENCES]() {
    return this.tenantReferences;
  }

  @Mutation
  [Mutations.SET_ALL_TENANTS](tenants) {
    this.allTenants = tenants;
  }

  @Mutation
  [Mutations.SET_TENANT](tenant) {
    this.tenant = tenant;
  }

  @Mutation
  [Mutations.SET_EXISTING_TENANT](tenant) {
    this.existingTenant = tenant;
  }

  @Mutation
  [Mutations.SET_TENANTS](tenants) {
    this.tenants = tenants;
  }

  @Mutation
  [Mutations.SET_SEARCH_TENANT_LIST](tenants) {
    this.tenants = tenants;
  }

  @Mutation
  [Mutations.SET_SEARCH_TENANT_ACTION_ERROR](error) {
    this.searchActionError = error;
  }

  @Mutation
  [Mutations.SET_TENANT_REFERENCES](references) {
    this.tenantReferences = references;
  }

  @Action
  [Actions.SEARCH_TENANT](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get('admin/tenants', '', {
        params: { ...params },
      } as AxiosRequestConfig)
        .then((response) => {
          this.context.commit(
            Mutations.SET_SEARCH_TENANT_LIST,
            response.data.data.tenants
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SEARCH_TENANT_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FIND_EXISTING_TENANT](payload, config = {} as AxiosRequestConfig) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('admin/tenants', payload, config)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EXISTING_TENANT, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SEARCH_TENANT_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.FIND_EXISTING_TENANT_BY_ID](id: string) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/tenants/${id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TENANT, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_SEARCH_TENANT_ACTION_ERROR,
            response.data
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ALL_TENANTS](params = {}) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/tenants`, '', {
        params: params,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_TENANTS, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_TENANT_LIST_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_TENANT_REFERENCES](payload) {
    console.log('TENANT REFERENCE', payload);
    return new Promise<void>((resolve, reject) => {
      ApiService.parameters(payload);
      ApiService.get(`admin/tenants/${payload.id}/references`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TENANT_REFERENCES, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_TENANT_LIST_ERROR, response.data);
          reject();
        });
    });
  }
}
