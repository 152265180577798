import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import UserModule from '@/store/modules/UserModule';
import TenantModule from '@/store/modules/TenantModule';
import BodyModule from '@/store/modules/BodyModule';
import BreadcrumbsModule from '@/store/modules/BreadcrumbsModule';
import ConfigModule from '@/store/modules/ConfigModule';
import Resources from '@/store/modules/ResourcesModule';
import TenantReviewModule from '@/store/modules/TenantReviewModule';
import ReviewQuestionModule from '@/store/modules/ReviewQuestionModule';
import ReferenceRequestModule from '@/store/modules/ReferenceRequestModule';
import SystemsModule from '@/store/modules/SystemsModule';
import ManageUserModule from '@/store/modules/ManageUserModule';
import VerifyAccountModule from '@/store/modules/VerifyAccountModule';
//import PayloadModule from '@/store/modules/PayloadModule';
import PlanModule from '@/store/modules/PlanModule';
import PrincipalModule from '@/store/modules/PrincipalModule';
import BusinessModule from '@/store/modules/BusinessModule';
import ForgotPasswordModule from '@/store/modules/ForgotPasswordModule';

import TemplatesModule from '@/store/modules/TemplatesModule';
import RemindersModule from '@/store/modules/RemindersModule';
import AgencyModule from '@/store/modules/AgencyModule';
import DashboardModule from '@/store/modules/DashboardModule';

import SubscriptionModule from '@/store/modules/SubscriptionModule';
import AssetModule from '@/store/modules/AssetModule';
import StartupGuideModule from '@/store/modules/StartupGuideModule';

config.rawError = true;

const store = createStore({
  modules: {
    Resources,
    BodyModule,
    ConfigModule,
    BreadcrumbsModule,
    UserModule,
    AuthModule,
    ForgotPasswordModule,
    TenantModule,
    ReferenceRequestModule,
    TenantReviewModule,
    ReviewQuestionModule,
    SystemsModule,
    VerifyAccountModule,
    PlanModule,
    PrincipalModule,
    BusinessModule,
    ManageUserModule,
    TemplatesModule,
    RemindersModule,
    AgencyModule,
    DashboardModule,
    SubscriptionModule,
    AssetModule,
    StartupGuideModule
  },
});

export default store;
