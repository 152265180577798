import ApiService from '@/core/services/ApiService';
import { Action, Mutation, Module, VuexModule } from 'vuex-module-decorators';

import { ReviewQuestion } from '@/models/ReviewQuestionModel';
import { StoreError, ReviewQuestionsModuleStore } from '@/models/StoreModel';

import { Actions, Getters, Mutations } from '../enums/ReviewQuestionsEnums';

@Module
export default class ReviewQuestionModule
  extends VuexModule
  implements ReviewQuestionsModuleStore
{
  actionError: StoreError | null = null;

  allReviewQuestions = [];
  reviewQuestion = {} as unknown as ReviewQuestion;
  reviewQuestions = [];

  listError = null;
  listLoading = false;

  /**
   * Get list of questions
   * @returns reviewQuestions
   */
  get [Getters.GET_ALL_REVIEW_QUESTIONS](): ReviewQuestion[] {
    return this.allReviewQuestions;
  }

  /**
   * Get list of questions
   * @returns reviewQuestions
   */
  get [Getters.GET_REVIEW_QUESTIONS](): ReviewQuestion[] {
    return this.reviewQuestions;
  }

  /**
   * Get review question
   * @returns reviewQuestions
   */
  get [Getters.GET_REVIEW_QUESTION](): ReviewQuestion {
    return this.reviewQuestion as unknown as ReviewQuestion;
  }

  /**
   * Get current error for the resource actions
   * @returns resource action error
   */
  get [Getters.GET_REVIEW_QUESTIONS_ACTION_ERROR](): StoreError {
    return this.actionError as unknown as StoreError;
  }

  /**
   * Get current error for the resource actions
   * @returns resource action error
   */
  get [Getters.GET_REVIEW_QUESTIONS_LIST_ERROR](): StoreError {
    return this.listError as unknown as StoreError;
  }

  @Mutation
  [Mutations.SET_ALL_REVIEW_QUESTIONS](reviewQuestions) {
    this.allReviewQuestions = reviewQuestions;
  }

  @Mutation
  [Mutations.SET_REVIEW_QUESTION](reviewQuestion) {
    this.reviewQuestion = reviewQuestion;
  }

  @Mutation
  [Mutations.SET_REVIEW_QUESTIONS](reviewQuestions) {
    this.reviewQuestions = reviewQuestions;
  }

  @Mutation
  [Mutations.SET_REVIEW_QUESTIONS_ACTION_ERROR](actionError) {
    this.actionError = actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_REVIEW_QUESTIONS_LIST_ERROR](listError) {
    this.listError = listError;
  }

  @Mutation
  [Mutations.SET_REVIEW_QUESTIONS_LIST_LOADING](loading) {
    this.listLoading = loading;
  }

  @Action({ rawError: true })
  [Actions.FETCH_REVIEW_QUESTION]() {
    this.context.commit(Mutations.SET_REVIEW_QUESTIONS_LIST_LOADING, true);

    return new Promise<void>((resolve, reject) => {
      ApiService.get('admin/questions')
        .then(({ data }) => {
          this.context.commit(Mutations.SET_REVIEW_QUESTIONS, data.data);
          resolve();
        })
        .catch(({ data }) => {
          this.context.commit(
            Mutations.SET_REVIEW_QUESTIONS_ACTION_ERROR,
            data
          );
          reject();
        })
        .finally(() => {
          this.context.commit(
            Mutations.SET_REVIEW_QUESTIONS_LIST_LOADING,
            false
          );
        });
    });
  }
}
