import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import store from '@/store';

const ProfileRouteGuard = {
  isPrincipal: () => async (to, from, next) => {
    const user = await store.getters[AuthGetters.GET_AUTH_USER];
    const hasAccess = user.roles.includes('principal');

    if (!hasAccess) {
      return next({ name: 'my-profile-over-view' });
    }

    return next();
  },
};

export default ProfileRouteGuard;
