import CookieService from '@/core/services/CookieService';
import store from '@/store';
import { Actions, Getters } from '@/store/enums/VerifyAccountEnums';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import toasts from '@/utils/toasts';

const AccountVerification = {
  token: () => async (to, from, next) => {
    const payload = {
      token: to.params.token,
      t: typeof to.query.t !== 'undefined' ? to.query.t : false,
    };

    // verify account
    return await store
      .dispatch(Actions.VERIFY_ACCOUNT_TOKEN, payload)
      .then(async () => {
        // fetch current user before going to complete profile
        const user = await store.getters[AuthGetters.GET_AUTH_USER];
        const bearerToken = await store.getters[AuthGetters.GET_AUTH_TOKEN];

        console.log('AccountVerification', {
          user,
          bearerToken,
        });
        CookieService.set('sessionToken', bearerToken);

        // await query params to be available
        const urlQuery = await to.query;

        if (user.skip_complete_profile) {
          return next({ name: 'dashboard', query: urlQuery });
        }

        return next({ name: 'complete-account-profile', query: urlQuery });
      })
      .catch(() => {
        toasts.error(
          [],
          'Verify account link might have expired. Please login to request a new one.'
        );
        return next({ name: 'sign-in' });
      });
  },
  main: () => async (to, from, next) => {
    const accountIsVerified = CookieService.get('accountIsVerified');
    const accountIsUpdated = CookieService.get('accountIsUpdated');

    if (!accountIsVerified && !accountIsUpdated) {
      return next({ name: 'validate-account-verify-token' });
    }

    if (accountIsVerified && accountIsUpdated) {
      return next({ name: 'dashboard' });
    }

    next();
  },
  request: () => async (to, from, next) => {
    return await store
      .dispatch(Actions.REQUEST_NEW_TOKEN)
      .then(() => {
        toasts.success('Verification link successfully sent.');
        next();
      })
      .catch(() => {
        const { errors, messages } = store.getters[Getters.GET_ACTION_ERROR];
        toasts.error(errors, messages);
        next();
      });
  },
};

export default AccountVerification;
