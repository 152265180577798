enum Actions {
  FETCH_STATISTICS = 'fetchStatistics',
}

enum Mutations {
  SET_STATISTICS = 'setAgencies',
  SET_ERRORS = 'setDashboardErrors',
}

enum Getters {
  GET_STATISTICS = 'getStatistics',
  GET_ERRORS = 'getDashboardErrors',
}

export { Actions, Mutations, Getters };
