enum Actions {
  FETCH_ROOT_FOLDERS = 'fetchRootFolders',
  FETCH_FOLDERS = 'fetchFolders',
  CREATE_NEW_FOLDER = 'createNewFolder',
  DELETE_FOLDER = 'deleteFolder',
  DELETE_FILE = 'deleteFile',
  PUSH_CRUMB_CHILD = 'pushAssetBreadcrumbs',
  POP_CRUMB_CHILD = 'popAssetBreadcrumbs',
  UPLOAD_FILE = 'uploadFile',
}

enum Mutations {
  SET_CURRENT_FOLDER = 'setAssetCurrentFolder',
  SET_PARENT_FOLDER = 'setAssetParentFolder',
  SET_DIRECTORIES = 'setAssetDirectories',
  SET_FOLDERS = 'setAssetFolders',
  SET_BREADCRUMBS = 'setAssetBreadcrumbs',
  SET_ERRORS = 'setAssetErrors',
}

enum Getters {
  GET_CURRENT_FOLDER = 'getAssetCurrentFolder',
  GET_PARENT_FOLDER = 'getAssetParentFolder',
  GET_DIRECTORIES = 'getAssetDirectories',
  GET_FOLDERS = 'getAssetFolders',
  GET_BREADCRUMBS = 'getAssetBreadcrumbs',
  GET_BREADCRUMB_PARENT = 'getParentAssetBreadcrumb',
  GET_BREADCRUMB_CURRENT = 'getCurrentAssetBreadcrumb',
  GET_ERRORS = 'getAssetErrors',
}

export { Actions, Mutations, Getters };
