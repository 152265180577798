import store from '@/store';
import { Actions } from '@/store/enums/TenantEnums';

const SubscriptionRouteGuard = {
  hasNoSubscription: () => async (to, from, next) => {
    //if the auth user has subscription
    next();

    //if no subscription
    // next({ name: 'plan'});
  },
  hasSubscription: () => async (to, from, next) => {
    next();
  },
};

export default SubscriptionRouteGuard;
