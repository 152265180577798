import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import store from '@/store';
import { Actions, Getters, Mutations } from '@/store/enums/AuthEnums';

const ProtectedRouteGuard = {
  isLoggedIn: () => async (to, from, next) => {
    const sessionToken = JwtService.getToken();

    console.log('sessionToken', sessionToken);

    ApiService.setAuthorization(sessionToken);
    return await store
      .dispatch(Actions.VERIFY_AUTH)
      .then(async () => {
        const authStatus = await store.getters[Getters.GET_AUTH_STATUS];

        console.log('authStatus', { authStatus });

        if (!authStatus && to.name == 'view-tenant-review') {
          return next({
            name: 'sign-in-options',
            query: { ref: to.params.refNo },
          });
        }

        if (!authStatus) {
          return next({ name: 'sign-in' });
        }

        await store.dispatch(Actions.FETCH_AUTH_USER);

        return next();
      })
      .catch(async () => {
        store.commit(
          Mutations.SET_AUTH_ROUTE_ERROR,
          'Please login to continue.'
        );

        const authStatus = await store.getters[Getters.GET_AUTH_STATUS];

        if (!authStatus && to.name == 'view-tenant-review') {
          return next({
            name: 'sign-in-options',
            query: { ref: to.params.refNo },
          });
        }

        return next({ name: 'sign-in' });
      });
  },
  main: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const urlQuery = await to.query;

    console.log('main', {
      type: typeof user,
      user: user,
    });

    //check if user store is exsiting
    if (typeof user === 'undefined' || Object.values(user).length === 0) {
      return next({ name: 'sign-in' });
    }

    if (typeof user === 'object' && user.roles.includes('admin')) {
      return next();
    }

    // trap negative
    if (
      to.name === 'dashboard' &&
      user.profile_completed_at !== null &&
      user.skip_profile_complete &&
      user.verified_at !== null
    ) {
      return next({ query: urlQuery });
    }

    return next();
  },
  verified: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const urlQuery = await to.query;
    console.log('verified', {
      type: typeof user,
      user: user,
    });

    //check if user store is exsiting
    if (typeof user.id === 'undefined') {
      return next({ name: 'sign-in' });
    }

    if (to.name === 'request-account-verification-mail') {
      return next();
    }

    if (to.name === 'dashboard') {
      return next();
    }
    return next();
  },
  isVerified: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const urlQuery = await to.query;

    //check if user store is exsiting
    if (typeof user.id === 'undefined') {
      return next({ name: 'sign-in' });
    }

    if (user.verified_at === null) {
      return next({ name: 'request-account-verification-mail' });
    }

    if (user.skip_complete_profile) {
      return next();
    }

    return next();
  },
  isNotVerified: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];

    console.log('isVerified', {
      type: typeof user,
      user: user,
    });

    //check if user store is exsiting
    if (typeof user !== 'undefined') {
      if (to.name === 'request-account-verification-mail') {
        return next();
      }

      if (to.name === 'dashboard') {
        return next();
      }
    }
    return next();
  },
  isAdminLevel: () => async (to, from, next) => {
    const user = await store.getters[Getters.GET_AUTH_USER];
    const allowedUserRoles = ['admin', 'principal'];

    if (typeof user.roles !== 'undefined') {
      Array.from(user.roles).forEach((role: string | unknown) => {
        if (allowedUserRoles.includes(role as unknown as string)) {
          return next();
        }
      });
    }

    return next({ name: 'dashboard' });
  },
};

export default ProtectedRouteGuard;
