import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Getters, Mutations } from '@/store/enums/AssetEnums';
import { AssetModuleStore, StoreError } from '@/models/StoreModel';
import { Folder } from '@/models/FolderModel';
import { Breadcrumb } from '@/models/BreadcrumbModel';

@Module
export default class AssetModule
  extends VuexModule
  implements AssetModuleStore
{
  actionError: StoreError | null = null;

  parentFolder = '' as unknown as Folder;
  currentFolder = '' as unknown as Folder;
  allDirectory = [];
  allFolders = [];
  allBreadcrumbs = [] as unknown as Array<Breadcrumb>;
  breadcrumbs = [] as unknown as Array<Breadcrumb>;

  get [Getters.GET_DIRECTORIES](): Folder[] {
    return this.allDirectory;
  }

  get [Getters.GET_CURRENT_FOLDER](): Folder {
    return this.currentFolder;
  }

  get [Getters.GET_PARENT_FOLDER](): Folder {
    return this.parentFolder;
  }

  get [Getters.GET_FOLDERS](): Folder[] {
    return this.allFolders;
  }

  get [Getters.GET_BREADCRUMBS](): Breadcrumb[] {
    return this.allBreadcrumbs;
  }

  get [Getters.GET_BREADCRUMB_PARENT](): Breadcrumb {
    return this.allBreadcrumbs.slice(-1) as unknown as Breadcrumb;
  }

  get [Getters.GET_BREADCRUMB_CURRENT](): Breadcrumb {
    return this.allBreadcrumbs.slice(-1) as unknown as Breadcrumb;
  }

  get [Getters.GET_ERRORS](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_DIRECTORIES](directories) {
    this.allDirectory = directories;
  }

  @Mutation
  [Mutations.SET_CURRENT_FOLDER](folder) {
    this.currentFolder = folder;
  }

  @Mutation
  [Mutations.SET_PARENT_FOLDER](folder) {
    this.parentFolder = folder;
  }

  @Mutation
  [Mutations.SET_FOLDERS](folders) {
    this.allFolders = folders;
  }

  @Mutation
  [Mutations.SET_BREADCRUMBS](beadcrumbs) {
    this.allBreadcrumbs = beadcrumbs;
  }

  @Mutation
  [Mutations.SET_ERRORS](errors) {
    this.actionError = errors;
  }

  @Action
  [Actions.PUSH_CRUMB_CHILD](crumb: Breadcrumb) {
    this.allBreadcrumbs.push(crumb);
  }

  @Action
  [Actions.POP_CRUMB_CHILD]() {
    this.allBreadcrumbs.pop();
  }

  @Action
  [Actions.FETCH_ROOT_FOLDERS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/assets`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DIRECTORIES, data.data.directory);
          this.context.commit(Mutations.SET_BREADCRUMBS, data.data.breadcrumbs);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_FOLDERS](folder) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/assets/folders`, folder)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_FOLDER, data.data.current);
          this.context.commit(Mutations.SET_PARENT_FOLDER, data.data.parent);
          this.context.commit(Mutations.SET_DIRECTORIES, data.data.directory);
          this.context.commit(Mutations.SET_BREADCRUMBS, data.data.breadcrumbs);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_NEW_FOLDER](payload) {
    return new Promise<void>((resolve, reject) => {
      let route = `admin/assets`;
      if (payload.currentFolder) {
        route = `admin/assets/folders/${payload.currentFolder}`;
      }
      ApiService.post(route, payload)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_FOLDER](folder) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`admin/assets/folders/${folder.id}`)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_FILE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(
        `admin/assets/folders/${payload.currentFolder}/file/${payload.file}`
      )
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }

  @Action
  [Actions.UPLOAD_FILE](payload) {
    return new Promise<void>((resolve, reject) => {
      const formData = new FormData();

      for (let i = 0; i < payload.folderFiles.length; i++) {
        formData.append('files[]', payload.folderFiles[i]);
      }

      ApiService.post(`admin/assets/${payload.currentFolder}/files`, formData)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERRORS, response);
          reject();
        });
    });
  }
}
