import ApiService from '@/core/services/ApiService';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

import { Mutations as AuthMutations } from '@/store/enums/AuthEnums';
import { Actions, Getters, Mutations } from '@/store/enums/UserEnums';

import { UserModuleStore, StoreError, SystemError } from '@/models/StoreModel';
import { User } from '@/models/UserModel';
import CookieService from '@/core/services/CookieService';
import { AxiosRequestConfig } from 'axios';

@Module
export default class UserModule extends VuexModule implements UserModuleStore {
  actionError: StoreError | null = null;
  systemError: SystemError | null = null;

  allUsers = [];
  user = {} as unknown as User;
  users = [];

  error: StoreError | null = null;
  listError: StoreError | null = null;
  listLoading = false;

  avatar = '';

  get [Getters.GET_ALL_USERS](): User[] {
    return this.users;
  }

  get [Getters.GET_AVATAR](): string {
    return this.avatar;
  }

  @Mutation
  [Mutations.SET_AVATAR](avatar) {
    this.avatar = avatar;
  }

  @Mutation
  [Mutations.SET_ALL_USERS](users) {
    this.users = users;
  }

  /**
   * Get users
   * @returns array
   */
  get [Getters.GET_USERS](): User[] {
    return this.users;
  }

  @Mutation
  [Mutations.SET_USERS](users) {
    this.users = users;
  }

  /**
   * Get user
   * @returns array
   */
  get [Getters.GET_USER](): User {
    return this.user as unknown as User;
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  /**
   * Get authentication errors
   * @returns array
   */
  get [Getters.GET_USER_LIST_ERROR](): StoreError {
    return this.listError as StoreError;
  }

  @Mutation
  [Mutations.SET_USER_LIST_ERROR](error) {
    this.listError = error;
  }

  /**
   * Get authentication errors
   * @returns array
   */
  get [Getters.GET_USER_ACTION_ERROR](): StoreError {
    return this.actionError as StoreError;
  }

  @Mutation
  [Mutations.SET_USER_ACTION_ERROR](error) {
    this.actionError = error;
  }

  @Action
  [Actions.CREATE_NEW_USER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post('admin/users', payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_USER_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/users/${payload.id}`, payload)
        .then(() => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_USER_ACTION_ERROR, response.data);
          reject();
        });
    });
  }

  @Action
  [Actions.FETCH_AVATAR](userId) {
    return new Promise<void>((resolve, reject) => {
      const axiosRequestConfig = {
        responseType: 'arraybuffer',
      } as AxiosRequestConfig;
      ApiService.query(
        `admin/users/${userId}/profile/picture`,
        axiosRequestConfig
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_USER_ACTION_ERROR, response.data);
          reject();
        });
    });
  }
}
